import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { ICON_LIST } from "../../common/icons";
import * as navActions from "../../common/actions";
import { createSelector } from "../../common/orm";
import { RecordSection } from "../models";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
// import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "auto",

        [theme.breakpoints.down("960")]: {
            marginBottom: 6,
        },
        [theme.breakpoints.down("601")]: {
            display: "none",
        },
    },
    root1: {
        //used for smaller screens to not show menu component of sections
        // backgroundColor: theme.palette.background.paper,
        width: "auto",
        marginTop: 41,
        [theme.breakpoints.up("601")]: {
            display: "none",
        },
    },
    scroller: {
        width: "100%",
        overflowX: "hidden",
    },
    fullWidth: {
        flexGrow: 1,
        maxWidth: "none",
        flexBasis: 0,
        flexShrink: 1,
        alignItems: "start"
    },
    topListItem: {
        borderBottom: "1px solid #262626",
        backgroundColor: "#eee",
        cursor: "default",
        textAlign: "center",
    },
    listItem: {
        borderBottom: "1px solid #262626",
        paddingLeft: 33,
    },
});

//for smaller screens menu component
const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
        width: "88%",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
)); //for smaller screens menu component
const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const getSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageId) => {
        // A list of all sections within the page
        return session.Section.filter((sect) => sect.page === pageId)
            .orderBy("order")
            .toRefArray();
    }
);

const getPage = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        // Get the specific page we are on
        return session.Page.withId(pageID);
    }
);
const getRecordSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, pr) => {
        return session.RecordSection.filter((rs) => rs.parentrecord === pr).toRefArray();
    }
);

class SectionTabHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            value: null,
        };
    }
    //set index and name of section
    handleChange = (event, value) => {
        const { page, sections, history, rs } = this.props;

        var c = sections.find((s) => {
            return s.id === value;
        });

        // You clicked on the parent - Go straight to the record
        if (c.parent) {
            history.push(
                "/page/" +
                    page.id +
                    "/parentrecord/" +
                    rs[0].parentrecord +
                    "/section/" +
                    value +
                    "/recordsection/" +
                    rs[0].parentinfo.parent_id +
                    "/form"
            );
        } else {
            if (c.repeatable_subform || c.iframe_url_section) {
                history.push(
                    //repeatable subform when true will only show one recordsection
                    "/page/" + page.id + "/parentrecord/" + rs[0].parentrecord + "/section/" + value + "/subform"
                );
            } else {
                // Otherwise you clicked on a child - Go to the table
                history.push("/page/" + page.id + "/parentrecord/" + rs[0].parentrecord + "/section/" + value + "/form");
            }
        }
    };

    //for smaller screens menu component
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    render() {
        const { classes, sections, value, setTabRef, navState } = this.props;
        const { anchorEl, thisValue } = this.state;
        if (value && value !== thisValue) this.setState({ thisValue: value }); //used to stop 'action' on tabs from recalling componentWillupdate

        //this is needed for collapsing content window with differnt sizes due to theme not having access to navState
        let setWidth;
        if (window.innerWidth > 1279 && navState.pagesOpen) setWidth = "calc(100% - 375px)";
        else if (window.innerWidth > 1279 && !navState.pagesOpen) setWidth = "calc(100% - 111px)";
        else if (window.innerWidth < 1279 && window.innerWidth > 960 && navState.pagesOpen) setWidth = "calc(100% - 330px)";
        else if (window.innerWidth < 960 && window.innerWidth >= 601 && navState.pagesOpen) setWidth = "calc(100% - 90px)";
        else if (window.innerWidth < 960 && window.innerWidth >= 601 && !navState.pagesOpen) setWidth = "calc(100% - 92px)";
        else if (window.innerWidth < 601 && !navState.pagesOpen) setWidth = "calc(100% - 34px)";
        else if (window.innerWidth < 1279 && !navState.pagesOpen) setWidth = "calc(100% - 105px)";

        return (
            <>
                <div
                    className={classes.root}
                    style={{
                        position: "fixed",
                        zIndex: 2,
                        marginTop: window.innerWidth < 1135 ? 41 : 50,
                        width: setWidth,
                    }}>
                    <AppBar position="static" color="default" style={{ maxHeight: 82 }}>
                        <Tabs
                            action={(ref) => {
                                if (value && value !== thisValue) {
                                    setTabRef(ref);
                                }
                            }} //pass reference to setTabRef function in actions.js---used to realign underbar under tab when collapsing sidebar
                            value={value}
                            onChange={this.handleChange}
                            variant="scrollable"
                            indicatorColor="primary"
                            textColor="primary"
                            classes={{ scroller: classes.scroller }}>
                            {sections.map((section, index) => {
                                //check for icons if they are present if they are we will return them
                                if (section.icon !== undefined) {
                                    const icon_array = ICON_LIST.find(function (itm) {
                                        return itm.id === section.icon;
                                    });
                                    var icon_component = "";
                                    if (icon_array) icon_component = icon_array["component"];
                                }
                                return (
                                    <Tab
                                        classes={{ root: classes.fullWidth }}
                                        key={icon_component + index}
                                        icon={<Icon style={{color:section.icon_color}}>{icon_component}</Icon>}
                                        label={section.name}
                                        value={section.id}
                                    />
                                );
                            })}
                        </Tabs>
                    </AppBar>
                </div>
                {/* small menu component only for screens under 601px */}
                <div className={classes.root1}>
                    <Button
                        style={{ width: "84.5%", marginBottom: 6, marginTop: 50, zIndex: 3, position: "fixed" }}
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={this.handleClick}>
                        Module Sections
                    </Button>
                    <StyledMenu
                        style={{ width: "98%" }}
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}>
                        <div className={classes.root1}>
                            {sections.map((section, index) => {
                                if (section.icon !== undefined) {
                                    const icon_array = ICON_LIST.find(function (itm) {
                                        return itm.id === section.icon;
                                    });
                                    var icon_component = "";
                                    if (icon_array) icon_component = icon_array["component"];
                                }
                                if (index === 0) {
                                    return (
                                        <>
                                            <ListItem button className={classes.topListItem}>
                                                <ListItemText
                                                    primary={<Typography variant="h6">Sections</Typography>}
                                                    classes={{
                                                        root: classes.topListText,
                                                        primary: classes.topListText,
                                                    }}
                                                />
                                            </ListItem>
                                            <StyledMenuItem
                                                className={classes.listItem}
                                                onClick={(e) => {
                                                    //eslint-disable-next-line
                                                    return this.handleChange(e, section.id), this.handleClose();
                                                }}>
                                                <ListItemIcon>
                                                    <Icon style={{color:section.icon_color}}>{icon_component} </Icon>
                                                </ListItemIcon>
                                                <ListItemText primary={section.name} />
                                            </StyledMenuItem>
                                        </>
                                    );
                                }
                                return (
                                    <StyledMenuItem
                                        className={classes.listItem}
                                        onClick={(e) => {
                                            //eslint-disable-next-line
                                            return this.handleChange(e, section.id), this.handleClose();
                                        }}>
                                        <ListItemIcon>
                                            <Icon style={{color:section.icon_color}}>{icon_component} </Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={section.name} />
                                    </StyledMenuItem>
                                );
                            })}
                        </div>
                    </StyledMenu>
                </div>
            </>
        );
    }
}
SectionTabHeader.displayName = "SectionTabHeader";

SectionTabHeader = connect(
    (state, ownProps) => ({
        page: getPage(state, ownProps),
        rs: getRecordSection(state, ownProps),
        sections: getSections(state, ownProps),
        navState: state.nav,
    }),
    {
        ...RecordSection.actions,
        ...navActions,
    }
)(SectionTabHeader);

export default withStyles(styles, { withTheme: true })(withRouter(SectionTabHeader));
