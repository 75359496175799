import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { loadModules } from "esri-loader";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import * as navActions from "../actions";
import { MuiThemeProvider } from "@material-ui/core/styles/";
import { theme } from "../../../api/constants";
import { createSelector } from "../orm";
import Control from "./Control";
import MapEditing from "../../page/map/MapEditing";
import MapSearch from "./MapSearch";

// eslint-disable-next-line
var showChecked = false; //used for checkboxes showing checkd or not for each layer

const settings = createSelector((schema) => {
    return schema.MapSetting.first();
});

const layers = createSelector((schema) => {
    return schema.MapLayer.all().orderBy("order").toModelArray();
});

const getGroupings = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageId) => {
        return session.SectionMapGrouping.all().orderBy("id").toRefArray();
    }
);

const getValueGroupings = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageId) => {
        return session.GroupingValue.all().orderBy("id").toRefArray();
    }
);

const getPageID = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageId) => {
        return pageId;
    }
);

// Get the allpage geometries, uses additional props for checking as routes will come from mutliple places
const allPages = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (state, ownProps) => parseInt(ownProps.match.params["userId"]), // Global Map Only to check permissions
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (schema, pageID, sectionID, userId, recordSectionID) => {
        showChecked = "All";
        
        if (pageID && sectionID !== 0) {
            // ExpansionPanelForm, ExpansionPanelMap, Specific Search
            if (recordSectionID && !isNaN(recordSectionID)) {
                // Work around for ExpansionPanelMap that doesn't have section in URL
                const recsec = schema.RecordSection.filter((a) => a.id === recordSectionID).toRefArray();
                if (recsec.length > 0) showChecked = recsec[0].section;
                else showChecked = sectionID;
            } else {
                showChecked = sectionID;
            }
        }


        // Global Map - Filter by permissions
        if (!pageID && !sectionID) {
            const permission = schema.Permission.filter(p => 
                p.user === userId && p.noaccess !== true
            ).orderBy("id").toRefArray();

            return schema.Page.filter(p => {
                var x = 0;
                while (x < permission.length) {
                    if (permission[x].page === p.id) {
                        x++;
                        return p;
                    }
                    x++;
                }
                return null;
            }).orderBy("order").toModelArray();
        } 
        // Other Maps - Get all pages (no user id)
        else {
            return schema.Page.all().orderBy("order").toModelArray();
        }
    }
);

const getSections = createSelector((schema) => {	
    //improve in future to not get different city sections	
    return schema.Section.all().orderBy("page_id").toRefArray();	
});

// This selector will only get the advance search that was specifically navigated to
const getActiveSearch = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["advId"]),
    (session, advID) => {
        if (advID) {
            const advGroup = session.AdvancedSearch.filter((a) => a.id === advID).toRefArray();
            if (advGroup.length > 0)
                return session.AdvancedGroup.filter((a) => a.id === advGroup[0].advancedgroup)
                    .orderBy("id")
                    .toModelArray()
                    .map((ag) => ({
                        advancedsearch: ag.advancedsearch
                            .filter((a) => a.id === advID && a.geometriesonmap === true)
                            .orderBy("id")
                            .toRefArray(),
                        ...ag.ref,
                    }));
        }
    }
);

// This selector will get all advance searches that should be OFF by default
const getAdvancedSearches = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["userId"]),
    (state, ownProps) => parseInt(ownProps.match.params["advId"]),
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (schema, userID, advID, pageID) => {
        // Used on the global map to display all advance searches
        // Looks like its reading the user permissions and determining whether or not if they have access to that layer
        if (userID) {
            var allPages = [];
            schema.Permission.filter((p) => p.user === userID && p.noaccess !== true)
                .orderBy("id")
                .toRefArray()
                .forEach((p) => {
                    allPages.push(p.page);
                });

            //eslint-disable-next-line
            return schema.AdvancedGroup.filter((a) => {
                for (var p = 0; p < allPages.length; p++) {
                    if (allPages[p] === a.page) {
                        return a;
                    }
                }
            })
                .orderBy("id")
                .toModelArray()
                .map((ag) => ({
                    advancedsearch: ag.advancedsearch
                        .filter((a) => a.geometriesonmap === true)
                        .orderBy("id")
                        .toRefArray(),

                    ...ag.ref,
                }));
        } else if (pageID) {
            // Otherwise we are on a specific page (always?) so just get all searches for the page
            // But filter out if we are on a specific search so it's not shown double
            return schema.AdvancedGroup.filter((a) => a.page === pageID)
                .orderBy("id")
                .toModelArray()
                .map((ag) => ({
                    advancedsearch: ag.advancedsearch
                        .filter((a) => a.geometriesonmap === true && a.id !== advID)
                        .orderBy("id")
                        .toRefArray(),

                    ...ag.ref,
                }));
        }
    }
);

const getActiveRecordSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSectionId) => {
        return recordSectionId;
    }
);

const styles = (theme) => ({
    fullHeight: {
        height: "calc(100% + 16px)",
        margin: "0 !important",
        width: "100% !important",
        marginTop: "-16px !important",
        paddingBottom: "0 !important",
        [theme.breakpoints.down("960")]: {
            paddingLeft: "47px !important",
            paddingTop: "16px !important",
        },
    },
    height: {
        height: "calc(100% + 16px)",
        margin: "0 !important",
        width: "100% !important",
        //marginTop: "-16px !important",
        paddingBottom: "0 !important",
    },
    smallHeight: {
        height: 500,
        marginTop: "12px !important",
        marginBottom: "12px !important",
    },
});

export class BaseMapControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drawTouched: false,
            measurementOpen: false,
            geometry: null
        };

        this.mapRef = React.createRef();
    }

    historyPush = (url) => {
        const { history } = this.props;
        history.push(url);
    };
    componentDidUpdate(nextProps){// only for small view of map in expansion panel
        if(this.state.geometry && nextProps.geometry && this.state.geometry !== nextProps.geometry){
            if(this.tempGraphicsLayer){
                this.tempGraphicsLayer.removeAll()
                this.loadGeometry(nextProps.geometry)
            } 
        } 
    }

    loadGeometry = (geometry) =>{
        var _this = this;
        if (geometry) {
            var coord;
            var temp;
            var coords = [];
            loadModules(["esri/geometry/support/webMercatorUtils","esri/Graphic"], { css: true }).then(([webMercatorUtils,Graphic]) => {
                if (geometry.type === "Polygon") {
                    geometry.coordinates[0].forEach((x) => {
                        coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                    });
                    temp = _this._getPolygonGrpahic("blue", coords, _this.view, Graphic);
                    _this.tempGraphicsLayer.add(temp);
                } else if (geometry.type === "MultiPolygon") {
                    geometry.coordinates.forEach(function (c) {
                        coords = [];
                        c[0].forEach((x) => {
                            coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                        });
                        temp = _this._getPolygonGrpahic("blue", coords, _this.view, Graphic);
                        _this.tempGraphicsLayer.add(temp);
                    });
                } else if (geometry.type === "LineString") {
                    geometry.coordinates.forEach((x) => {
                        coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                    });
                    temp = _this._getLineGrpahic("blue", coords, _this.view, Graphic);
                    _this.tempGraphicsLayer.add(temp);
                } else if (geometry.type === "MultiLineString") {
                    geometry.coordinates.forEach((x) => {
                        coords = [];
                        x.forEach((l) => {
                            coords.push(webMercatorUtils.lngLatToXY(l[0], l[1]));
                        });
                        temp = _this._getLineGrpahic("blue", coords, _this.view, Graphic);
                        _this.tempGraphicsLayer.add(temp);
                    });
                } else if (geometry.type === "MultiPoint") {
                    geometry.coordinates.forEach(function (c) {
                        coord = webMercatorUtils.lngLatToXY(c[0], c[1]);
                        temp = _this._getPointGrapicWeb("blue", coord[0], coord[1], _this.view, Graphic);
                        _this.tempGraphicsLayer.add(temp);
                    });
                } else {
                    coord = webMercatorUtils.lngLatToXY(geometry.coordinates[0], geometry.coordinates[1]);
                    temp = _this._getPointGrapicWeb("blue", coord[0], coord[1], _this.view, Graphic);
                    _this.tempGraphicsLayer.add(temp);
                }
                _this.view.when(() => {
                    _this.view.goTo({
                        target: _this.tempGraphicsLayer.graphics.items, 
                        zoom: 14
                    });
                })
                if(_this.state.geometry !== geometry){
                    _this.setState({geometry})
                }
                    
            });
        }
    }

    componentDidMount() {
        const {
            layers,
            settings,
            viewOnly,
            saveRecordFieldBeforeMapRouting,
            geometry,
            setNull,
            setLoading,
            readOnly,
            pageMap,
            parentrecordMap,
            recordsectionMap,
            ormRecordFieldCreate,
            ormRecordSectionCreate,
            ormRecordSectionUpdateLocalOnly,
            ormRecordSectionUpdate,
            ormRecordSectionLoadDetailChild,
            ormRecordSectionMapUpdate,
            ormRecordFieldLoadDetailChild,
            setMapSettings,
            navState,
            sideform,
            authState, 
            page_id,
        } = this.props;

        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(
            [
                "esri/Basemap",
                "esri/widgets/Fullscreen",
                "esri/layers/GraphicsLayer",
                "esri/widgets/Home",
                "esri/Map",
                "esri/views/MapView",
                "esri/widgets/Popup",
                "esri/widgets/Search",
                "esri/widgets/Track",
                "esri/core/watchUtils",
                "esri/config",
                "esri/Graphic",
            ],
            { css: true }
        ).then(([Basemap, Fullscreen, GraphicsLayer, Home, ArcGISMap, MapView, Popup, Search, Track, watchUtils, esriConfig, Graphic]) => {
            var _this = this;
            const layerBases = layers.filter((l) => l.is_basemap);

            esriConfig.apiKey = "AAPKf2e3a0e95055432995c143c76ec9d7acolbgIHxUiuqnTzAJN-soT9O7nlVATKm_6FrquwAVqfTuXBi0QAUuHui-C7NqPl7j";

            // Add Base Layers
            this.baseLayers = [];
            this.baseLayers.push({ name: "Imagery with Labels", id: -1, map_l: new Basemap.fromId("arcgis-imagery") });
            this.baseLayers.push({ name: "Imagery", id: -2, map_l: new Basemap.fromId("satellite") });
            this.baseLayers.push({ name: "Streets", id: -3, map_l: new Basemap.fromId("streets-vector") });
            this.baseLayers.push({ name: "Topographic", id: -4, map_l: new Basemap.fromId("topo-vector") });

            var basemap = null;
            const addBase = (layer, x) => {
                if (layer) {
                    basemap = new Basemap({
                        baseLayers: [layer],
                        listMode: "hide",
                    });
                    this.baseLayers.push({ name: x.name, id: x.id, map_l: basemap });
                }
            };

            layerBases.forEach((x) => {
                var layer = null;
                if (x.layer_type === "WMS") {
                    loadModules(["esri/layers/WMSLayer"], { css: true }).then(([WMSLayer]) => {
                        layer = new WMSLayer({
                            url: x.layerurl,
                            sublayers: x.wmslayers.split(",").map((x) => ({
                                name: x,
                            })),
                            spatialReference: {
                                wkid: 3857
                              },
                            title: x.name,
                            listMode: "hide",
                        });
                        addBase(layer, x);
                    });
                } else if (x.layer_type === "Tiled" || x.layer_type === "ArcGIS") {
                    if (x.tileddynamic === "Dynamic") {
                        loadModules(["esri/layers/MapImageLayer"], { css: true }).then(([MapImageLayer]) => {
                            layer = new MapImageLayer({
                                url: x.layerurl,
                                title: x.name,
                                listMode: "hide",
                            });
                            addBase(layer, x);
                        });
                    } else {
                        loadModules(["esri/layers/TileLayer"], { css: true }).then(([TileLayer]) => {
                            layer = new TileLayer({
                                url: x.layerurl,
                                title: x.name,
                                listMode: "hide",
                            });
                            addBase(layer, x);
                        });

                    }
                }
            });

            var baselayerid = 0;
            var settingIndex = this.baseLayers.findIndex((x) => x.id === settings.default_basemap_id);
            if (settingIndex === -1) settingIndex = 0;
            else {
                baselayerid = this.baseLayers.find((x) => x.id === settings.default_basemap_id).id;
            }

            this.tempGraphicsLayer = new GraphicsLayer({ listMode: "hide" });

            //add the basemaps to a map instance
            const map = new ArcGISMap({
                basemap: this.baseLayers[settingIndex].map_l,
                layers: [this.tempGraphicsLayer],
            });

            // Map View Set Up
            var zoom = 13;
            var center = [];
            this.layer_count = [];

            if (navState.map && navState.map.lat < 75 && navState.map.lat > 0 && navState.map.lng < 0 && navState.map.lng > -180) {
                zoom = navState.map.zoom;
                center = [navState.map.lat, navState.map.lng];
            } else {
                zoom = settings ? settings.map_level : 13;
                center = settings ? [settings.latitude, settings.longitude] : null;
            }

            // Allows Default Pop Up
            const defaultPopUp = new Popup({ defaultPopupTemplateEnabled: true });

            // MapView Instance, pass in map instance etc
            this.view = new MapView({
                container: this.mapRef.current,
                map: map,
                center: [center[1], center[0]],
                zoom,
                popup: defaultPopUp,
                spatialReference: {
                    wkid: 3857,
                },
            });

            watchUtils.whenFalse(this.view, "stationary", function () {
                if (!_this.view.stationary) {
                    watchUtils.whenTrueOnce(_this.view, "stationary", function () {
                        setMapSettings({
                            lat: _this.view.extent.center.latitude,
                            lng: _this.view.extent.center.longitude,
                            zoom: _this.view.zoom,
                        });
                    });
                }
            });

            var source = {
                countryCode: "US",
                defaultZoomScale: 2500,
                url: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer?preferredLabelValues=matchedCity",
                name: "ArcGIS World Geocoding Service",
                outFields: ["Addr_type", "Match_addr", "StAddr", "City"],
                placeholder: "Find address, place, or intersection",
                singleLineFieldName: "SingleLine",
                suggestionsEnabled: true,
                withinViewEnabled: false,
            };

            if (settings.geocoder_top) {
                loadModules(["esri/geometry/Extent"], { css: true }).then(([Extent]) => {
                    const theExtent = new Extent({
                        xmin: settings.geocoder_left,
                        ymin: settings.geocoder_bottom,
                        xmax: settings.geocoder_right,
                        ymax: settings.geocoder_top,
                        spatialReference: {
                            wkid: 4326,
                        },
                    });
                    source["filter"] = {
                        geometry: theExtent,
                    };
                });
            }

            var fullscreen = new Fullscreen({ view: this.view });
            var homeBtn = new Home({ view: this.view });
            var search = new Search({ view: this.view, includeDefaultSources: false, sources: [source] });
            homeBtn.goToOverride = function (view, goToParams) {
                return view.goTo({
                    target: [settings.longitude, settings.latitude],
                    zoom: settings.map_level,
                });
            };

            var track = new Track({ view: this.view });

            // Add Reference Layers
            this.referenceLayers = [];
            var has_webmap = false;

            layers
                .filter((x) => !x.is_basemap)
                .filter((x) => x.page === "All Modules" || x.page.toString() === page_id.toString())
                .forEach((x) => {
                    var layer = null;

                    if (x.layer_type === "Tiled") {
                        loadModules(["esri/layers/TileLayer"], { css: true }).then(([TileLayer]) => {
                            layer = new TileLayer({
                                url: x.layerurl,
                                opacity: x.opacity,
                                visible: x.on_by_default,
                                title: x.name,
                                listMode: "hide",
                            });
                            addRefLayer(layer);
                        });
                    } else if (x.layer_type === "WMS") {
                        loadModules(["esri/layers/WMSLayer"], { css: true }).then(([WMSLayer]) => {
                            layer = new WMSLayer({
                                url: x.layerurl,
                                sublayers: x.wmslayers.split(",").map((x) => ({
                                    name: x,
                                })),
                                opacity: x.opacity,
                                visible: x.on_by_default,
                                title: x.name,
                                listMode: "hide",
                            });
                            addRefLayer(layer);
                        });
                    } else if (x.layer_type === "ArcGIS") {
                        if (x.tileddynamic === "Tiled") {
                            loadModules(["esri/layers/TileLayer"], { css: true }).then(([TileLayer]) => {
                                layer = new TileLayer({
                                    url: x.layerurl,
                                    opacity: x.opacity,
                                    visible: x.on_by_default,
                                    title: x.name,
                                    listMode: "hide",
                                });
                                addRefLayer(layer);
                            });
                        } else if (x.tileddynamic === "Dynamic") {
                            const c = function (f) {
                                loadModules(["esri/layers/MapImageLayer"], { css: true }).then(([MapImageLayer]) => {
                                    layer = new MapImageLayer({
                                        url: x.layerurl,
                                        opacity: x.opacity,
                                        title: x.name,
                                        visible: x.on_by_default,
                                        listMode: "hide",
                                    });
                                    addRefLayer(layer);
                                });
                            };
                            c();
                        } else if (x.tileddynamic === "Feature Service") {
                            loadModules(["esri/layers/FeatureLayer"], { css: true }).then(([FeatureLayer]) => {
                                layer = new FeatureLayer({
                                    url: x.layerurl,
                                    opacity: x.opacity,
                                    title: x.name,
                                    visible: x.on_by_default,
                                    outFields: ["*"],
                                    listMode: "hide",
                                });
                                addRefLayer(layer);
                            });
                        }
                    } else if (x.layer_type === "Web Map") {
                        has_webmap = true;
                        loadModules(["esri/WebMap"], { css: true }).then(([WebMap]) => {
                            const webmap = new WebMap({
                                portalItem: {
                                    id: x.webmap_id,
                                },
                            });
                            webmap
                                .load()
                                .then(function () {
                                    // grab all the layers and load them
                                    const allLayers = webmap.allLayers;
                                    const promises = allLayers.map(function (layer) {
                                        return layer.load();
                                    });
                                    return Promise.all(promises.toArray());
                                })
                                .catch(function (error) {
                                    const layers = webmap.layers.items.filter((x) => (x.loadStatus = "loaded"));

                                    var has_group = false;
                                    layers.forEach((l, idx) => {
                                        if (l.type === "group") has_group = true;
                                    });

                                    layers.forEach((l, idx) => {
                                        if (l.type === "group" || !has_group) map.add(l);
                                    });

                                    loadModules(["esri/widgets/LayerList"], { css: true }).then(([LayerList]) => {
                                        var layerList = new LayerList({
                                            view: _this.view,
                                            container: "referencecontainer",
                                            listItemCreatedFunction: (event) => {
                                                const item = event.item;
                                                if (item.layer.type !== "group") {
                                                    item.panel = {
                                                        content: "legend",
                                                        open: item.layer.visible,
                                                    };
                                                    item.actionsSections = [
                                                        [
                                                            {
                                                                title: "Increase opacity",
                                                                className: "esri-icon-up",
                                                                id: "increase-opacity",
                                                            },
                                                            {
                                                                title: "Decrease opacity",
                                                                className: "esri-icon-down",
                                                                id: "decrease-opacity",
                                                            },
                                                        ],
                                                    ];
                                                }
                                            },
                                        });

                                        layerList.on("trigger-action", (event) => {
                                            const l_id = event.action.id;
                                            const l_layer = event.item.layer;
                                            if (l_id === "increase-opacity") {
                                                if (l_layer.opacity < 1) {
                                                    l_layer.opacity += 0.25;
                                                }
                                            } else if (l_id === "decrease-opacity") {
                                                if (l_layer.opacity > 0) {
                                                    l_layer.opacity -= 0.25;
                                                }
                                            }
                                        });
                                    });
                                })
                                .then(function (temp_layers) {
                                    if (!temp_layers) return;
                                    const layers = temp_layers;

                                    var has_group = false;
                                    layers.forEach((l, idx) => {
                                        if (l.type === "group") has_group = true;
                                    });

                                    layers.forEach((l, idx) => {
                                        if (l.type === "group" || !has_group) map.add(l);
                                    });

                                    loadModules(["esri/widgets/LayerList"], { css: true }).then(([LayerList]) => {
                                        var layerList = new LayerList({
                                            view: _this.view,
                                            container: "referencecontainer",
                                            listItemCreatedFunction: (event) => {
                                                const item = event.item;
                                                if (item.layer.type !== "group") {
                                                    item.panel = {
                                                        content: "legend",
                                                        open: item.layer.visible,
                                                    };
                                                    item.actionsSections = [
                                                        [
                                                            {
                                                                title: "Increase opacity",
                                                                className: "esri-icon-up",
                                                                id: "increase-opacity",
                                                            },
                                                            {
                                                                title: "Decrease opacity",
                                                                className: "esri-icon-down",
                                                                id: "decrease-opacity",
                                                            },
                                                        ],
                                                    ];
                                                }
                                            },
                                        });

                                        layerList.on("trigger-action", (event) => {
                                            const l_id = event.action.id;
                                            const l_layer = event.item.layer;
                                            if (l_id === "increase-opacity") {
                                                if (l_layer.opacity < 1) {
                                                    l_layer.opacity += 0.25;
                                                }
                                            } else if (l_id === "decrease-opacity") {
                                                if (l_layer.opacity > 0) {
                                                    l_layer.opacity -= 0.25;
                                                }
                                            }
                                        });
                                    });
                                });
                        });
                    }

                    //add all layers to the reference layer button is called from above in loadmodules
                    const addRefLayer = (layer) => {
                        if (layer) {
                            map.add(layer);

                            this.layer_count.push({
                                index: x.order,
                                count: 1,
                            });

                            // Create DomNode and pass to legend widget
                            const dNode = document.createElement("div");
                            loadModules(["esri/widgets/Legend"], { css: true }).then(([Legend]) => {
                                const legend = new Legend(
                                    {
                                        view: this.view,
                                        layerInfos: [
                                            {
                                                layer: layer,
                                            },
                                        ],
                                    },
                                    dNode
                                );
                                _this.referenceLayers.push({ layer: layer, order: x.order, legend: legend });
                            });
                        }
                    };
                });

            // Popup Action Listener
            this.view.popup.on("trigger-action", function (event) {
                if (this.view.popup.selectedFeature) {
                    var attributes = this.view.popup.selectedFeature.attributes;
                    var url;
                    if (event.action.id === "go-form") {
                        url =
                            "/page/" +
                            event.action.page_id +
                            "/parentrecord/" +
                            attributes["parentrecord_id"] +
                            "/section/" +
                            event.action.section_id +
                            "/recordsection/" +
                            attributes["__OBJECTID"];
                        window.open("#" + url + "/form");
                        //history.push(url + "/form");
                    } else if (event.action.id === "go-map") {
                        url =
                            "/page/" +
                            event.action.page_id +
                            "/parentrecord/" +
                            attributes["parentrecord_id"] +
                            "/recordsection/" +
                            attributes["__OBJECTID"];
                        window.open("#" + url + "/map");
                        //history.push(url + "/map");
                    } else if (event.action.id === "go-form2") {
                        url =
                            "/page/" +
                            event.action.page_id +
                            "/parentrecord/" +
                            attributes["parent_id"] +
                            "/section/" +
                            attributes["section"] +
                            "/recordsection/" +
                            attributes["pk"];
                        window.open("#" + url + "/form");
                        //history.push(url + "/form");
                    } else if (event.action.id === "go-map2") {
                        url = "/page/" + event.action.page_id + "/parentrecord/" + attributes["parent_id"] + "/recordsection/" + attributes["pk"];
                        window.open("#" + url + "/map");
                        //history.push(url + "/map");
                    }
                }
            });

            // Add Widgets
            if (!viewOnly) {
                this.parentContainer3 = document.createElement("div");
                this.view.ui.add(this.parentContainer3, { position: "top-left", index: 0 });
                ReactDOM.render(<MapSearch authState={authState} view={this.view} />, this.parentContainer3);
            }

            this.view.ui.add(fullscreen, "top-left");
            this.view.ui.add(search, { position: "top-left", index: 0 });
            this.view.ui.add(homeBtn, "top-left");
            this.view.ui.add(track, "top-left");

            // Top Right Parent Div
            this.parentContainer = document.createElement("div");
            this.parentContainer.className = "parentContainer";
            this.view.ui.add(this.parentContainer, "top-right");
            ReactDOM.render(this.renderTopRightBar(baselayerid, map, has_webmap), this.parentContainer);

            if (viewOnly) {
                // Edit Location Button
                this.parentContainer2 = document.createElement("div");
                this.parentContainer2.className = "parentContainer";
                this.view.ui.add(this.parentContainer2, "bottom-left");
                ReactDOM.render(
                    <MuiThemeProvider theme={theme}>
                        <Button variant="contained" color="primary" onClick={() => saveRecordFieldBeforeMapRouting()}>
                            Edit Location
                        </Button>
                    </MuiThemeProvider>,
                    this.parentContainer2
                );
            }

            // MAP Editing
            if (setNull) {
                // Edit Location Button
                this.parentContainer2 = document.createElement("div");
                this.view.ui.add(this.parentContainer2, "bottom-left");
                loadModules(["esri/geometry/support/webMercatorUtils"], { css: true }).then(([webMercatorUtils]) => {
                    ReactDOM.render(
                        <MuiThemeProvider theme={theme}>
                            <MapEditing
                                setNull={setNull}
                                map={map}
                                setLoading={setLoading}
                                GraphicsLayer={GraphicsLayer}
                                page={pageMap}
                                sideform={sideform}
                                historyPush={this.historyPush}
                                parentrecord={parentrecordMap}
                                recordsection={recordsectionMap}
                                ormRecordFieldCreate={ormRecordFieldCreate}
                                ormRecordSectionCreate={ormRecordSectionCreate}
                                ormRecordSectionUpdateLocalOnly={ormRecordSectionUpdateLocalOnly}
                                ormRecordSectionUpdate={ormRecordSectionUpdate}
                                ormRecordSectionMapUpdate={ormRecordSectionMapUpdate}
                                ormRecordSectionLoadDetailChild={ormRecordSectionLoadDetailChild}
                                ormRecordFieldLoadDetailChild={ormRecordFieldLoadDetailChild}
                                Graphic={Graphic}
                                _getPointGrapicWeb={this._getPointGrapicWeb}
                                _getLineGrpahic={this._getLineGrpahic}
                                _getPolygonGrpahic={this._getPolygonGrpahic}
                                webMercatorUtils={webMercatorUtils}
                                view={this.view}
                                readOnly={readOnly}
                            />
                        </MuiThemeProvider>,
                        this.parentContainer2
                    );
                });
            }

            // Cluster Zoom Click
            // Ideally this should also be on the map editing screen (!setnull) but that has a different click event for handling drawing
            if (!setNull) {
                this.view.on("click", function (event) {
                    _this.view.hitTest(event).then(function (response) {
                        response.results.forEach((x) => {
                            if (x.graphic.attributes && x.graphic.attributes.cluster_count) {
                                var opts = {
                                    duration: 500, // Duration of animation
                                };

                                _this.view.when(() => {
                                    _this.view.goTo(
                                        {
                                            target: x.graphic,
                                            zoom: _this.view.zoom + 2,
                                        },
                                        opts
                                    );
                                })
                            }
                        });
                    });
                });
            }

            // If we only have a single geometry object (the small inset map)
            _this.loadGeometry(geometry)
            // if (geometry) {
            //     var coord;
            //     var temp;
            //     var coords = [];
                
            //     loadModules(["esri/geometry/support/webMercatorUtils"], { css: true }).then(([webMercatorUtils]) => {
            //         if (geometry.type === "Polygon") {
            //             geometry.coordinates[0].forEach((x) => {
            //                 coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
            //             });
            //             temp = _this._getPolygonGrpahic("blue", coords, _this.view, Graphic);
            //             _this.tempGraphicsLayer.add(temp);
            //         } else if (geometry.type === "MultiPolygon") {
            //             geometry.coordinates.forEach(function (c) {
            //                 coords = [];
            //                 c[0].forEach((x) => {
            //                     coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
            //                 });
            //                 temp = _this._getPolygonGrpahic("blue", coords, _this.view, Graphic);
            //                 _this.tempGraphicsLayer.add(temp);
            //             });
            //         } else if (geometry.type === "LineString") {
            //             geometry.coordinates.forEach((x) => {
            //                 coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
            //             });
            //             temp = _this._getLineGrpahic("blue", coords, _this.view, Graphic);
            //             _this.tempGraphicsLayer.add(temp);
            //         } else if (geometry.type === "MultiLineString") {
            //             geometry.coordinates.forEach((x) => {
            //                 coords = [];
            //                 x.forEach((l) => {
            //                     coords.push(webMercatorUtils.lngLatToXY(l[0], l[1]));
            //                 });
            //                 temp = _this._getLineGrpahic("blue", coords, _this.view, Graphic);
            //                 _this.tempGraphicsLayer.add(temp);
            //             });
            //         } else if (geometry.type === "MultiPoint") {
            //             geometry.coordinates.forEach(function (c) {
            //                 coord = webMercatorUtils.lngLatToXY(c[0], c[1]);
            //                 temp = _this._getPointGrapicWeb("blue", coord[0], coord[1], _this.view, Graphic);
            //                 _this.tempGraphicsLayer.add(temp);
            //             });
            //         } else {
            //             coord = webMercatorUtils.lngLatToXY(geometry.coordinates[0], geometry.coordinates[1]);
            //             temp = _this._getPointGrapicWeb("blue", coord[0], coord[1], _this.view, Graphic);
            //             _this.tempGraphicsLayer.add(temp);
            //         }
            //         _this.view.when(() => {
            //             _this.view.goTo({
            //                 target: _this.tempGraphicsLayer.graphics.items, 
            //                 zoom: 14
            //             });
            //         })
            //         _this.setState({geometry})
            //     });
            // }
        });
    }

    componentWillUnmount() {
        if (this.view) {
            this.view.destroy();
        }
    }

    measurementToggle = () => {
        const { measurementOpen } = this.state;

        var _this = this;

        loadModules(["esri/widgets/Measurement"], { css: true }).then(([Measurement]) => {
            if (!measurementOpen) {
                _this.measurement = new Measurement({ view: this.view, activeTool: "distance", linearUnit: "miles" });
                _this.view.ui.add(_this.measurement, "bottom-right");
            } else {
                _this.measurement.clear();
                _this.view.ui.remove(_this.measurement);
            }
            _this.setState({ measurementOpen: !measurementOpen });
        });
    };

    measurementToggle2 = () => {
        const { measurementOpen } = this.state;

        var _this = this;

        loadModules(["esri/widgets/Measurement"], { css: true }).then(([Measurement]) => {
            if (!measurementOpen) {
                _this.measurement = new Measurement({ view: this.view, activeTool: "area", areaUnit: "square-us-feet" });
                _this.view.ui.add(_this.measurement, "bottom-right");
            } else {
                _this.measurement.clear();
                _this.view.ui.remove(_this.measurement);
            }
            _this.setState({ measurementOpen: !measurementOpen });
        });
    };

    /*changeBaseMap = (value) => {
        this.setState({ baseMapStr: value });
        var _this = this;

        loadModules(["esri/geometry/support/webMercatorUtils"], { css: true }).then(([webMercatorUtils]) => {
            const center = webMercatorUtils.xyToLngLat(_this.view.center.x, _this.view.center.y);
            _this.props.changedMapState({
                baseMapStr: value,
                zoom: _this.view.zoom,
                center: center,
            });
        });
    };*/

    renderTopRightBar = (intialBaselayerId, map, has_webmap) => {
        const {
            layers,
            settings,
            type,
            pages,
            authState,
            activeSearch,
            allAdvancedSearches,
            sections,
            viewOnly,
            activeRecordsection,
            mapGroups,
            groupValues,
            page_id,
        } = this.props;

        return (
            <>
                <div
                    title="Measure Distance"
                    className="esri-icon-measure esri-widget--button esri-widget esri-interactive"
                    onClick={this.measurementToggle}></div>
                <div
                    title="Measure Area"
                    className="esri-icon-measure-area esri-widget--button esri-widget esri-interactive"
                    onClick={this.measurementToggle2}></div>
                <Control
                    intialBaselayerId={intialBaselayerId}
                    type={type}
                    mapSettings={settings}
                    pages={pages}
                    layerRedux={layers}
                    view={this.view}
                    showChecked={showChecked}
                    baseLayerObj={this.baseLayers}
                    referenceLayerObj={this.referenceLayers}
                    //changeBaseMap={this.changeBaseMap}
                    authState={authState}
                    map={map}
                    searches={activeSearch}
                    allSearches={allAdvancedSearches}
                    sections={sections}
                    viewOnly={viewOnly}
                    activeRecordsection={activeRecordsection}
                    mapGroups={mapGroups}
                    groupValues={groupValues}
                    has_webmap={has_webmap}
                    page_id={page_id}
                />
            </>
        );
    };

    _getPointGrapicWeb = (color, x, y, view, Graphic, attributes = {}) => {
        var point = {
            type: "point",
            x: x,
            y: y,
            spatialReference: view.spatialReference,
        };

        var markerSymbol = {
            type: "simple-marker",
            color: color,
            width: 20,
            outline: {
                color: [255, 255, 255],
                width: 2,
            },
        };
        return new Graphic({
            geometry: point,
            symbol: markerSymbol,
            attributes: {
                key: performance.now(),
                ...attributes,
            },
        });
    };

    _getLineGrpahic = (color, vertices, view, Graphic, attributes = {}) => {
        return new Graphic({
            geometry: {
                type: "polyline",
                paths: vertices,
                spatialReference: view.spatialReference,
            },
            symbol: {
                type: "simple-line",
                color: color,
                width: 5,
                cap: "round",
                join: "round",
            },
            attributes: {
                key: performance.now(),
                ...attributes,
            },
        });
    };

    _getPolygonGrpahic = (color, vertices, view, Graphic, attributes = {}) => {
        var rgb = "0, 0, 255";
        if (color === "gray") rgb = "128, 128, 128";
        if (color === "red") rgb = "255, 0, 0";
        return new Graphic({
            geometry: {
                type: "polygon",
                rings: vertices,
                spatialReference: view.spatialReference,
            },
            symbol: {
                type: "simple-fill",
                style: "solid",
                color: "rgba(" + rgb + ", 0.2)",
                outline: {
                    color: "rgba(" + rgb + ", 1)",
                    width: 1,
                },
            },
            attributes: {
                key: performance.now(),
                ...attributes,
            },
        });
    };

    render() {
        const { classes, viewOnly, setNull } = this.props;

        return <div id="ms4map" className={viewOnly ? classes.smallHeight : setNull ? classes.height : classes.fullHeight} ref={this.mapRef} />;
    }
}

BaseMapControl = connect(
    (state, ownProps) => ({
        settings: settings(state),
        layers: layers(state),
        sections: getSections(state, ownProps),	
        pages: allPages(state, ownProps),
        navState: state.nav,
        activeSearch: getActiveSearch(state, ownProps),
        allAdvancedSearches: getAdvancedSearches(state, ownProps),
        authState: state.auth,
        activeRecordsection: getActiveRecordSection(state, ownProps),
        mapGroups: getGroupings(state, ownProps),
        groupValues: getValueGroupings(state, ownProps),
        page_id: getPageID(state, ownProps)
    }),
    {
        ...navActions,    
    }
)(BaseMapControl);

export default withStyles(styles)(withRouter(BaseMapControl));
